import React from "react";
import "./Topcarousel.module.css";
import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { withNamespaces } from "react-i18next";
import GetStore from "../../../getStore.js";
import Cookies from "universal-cookie";

const Topcarousel = ({ t }) => {
  const cookies = new Cookies();
  const storeData = GetStore();
  const redirectionUrl = cookies.get("rightTimeAlias")
    ? "https://" + cookies.get("rightTimeAlias") + ".righttime.rightcomtech.com"
    : "https://account.rightcomtech.com";

  const go = () => (window.location = redirectionUrl);
  return (
    <section
      id={"slider"}
      className={"Topcarousel"}
      data-testid="Topcarousel"
      style={{
        backgroundImage:
          "url(./assets/landing/Desktop/SVG/landing-page-desktop-background.svg)",
        backgroundPosition: "bottom",
        backgroundSize: "100% auto",
        backgroundRepeat: "no-repeat"
      }}
    >
      <Carousel className={"mainCarousel"}>
        <Carousel.Item>
          <Row style={{ alignItems: "center" }}>
            <Col md={2}></Col>
            <Col md={4}>
              <div className={"carouselItemText"}>
                <h1 className={"carouselTitle"}>{t("c1title")}</h1>
                <div className={"carouselText"}>
                  <p>{t("c1paragraph")}</p>
                </div>
                <div className={"d-none d-sm-none d-md-block"}>
                  <Button
                    onClick={go}
                    href={redirectionUrl}
                    variant="primary"
                    size="lg"
                    active
                  >
                    {t("get_started")}
                  </Button>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className={"carouselItemImage"}>
                <Image
                  src="/assets/landing/Desktop/SVG/Group 1240.svg"
                  style={{ height: "45vh" }}
                  fluid
                />
              </div>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row style={{ alignItems: "center" }}>
            <Col md={2}></Col>
            <Col md={4}>
              <div className={"carouselItemText"}>
                <h1 className={"carouselTitle"}>{t("c2title")}</h1>
                <div className={"carouselText"}>
                  <p className={"carouselText"}>{t("c2paragraph")}</p>
                </div>
                <div className={"d-none d-sm-none d-md-block"}>
                  <Button
                    href={redirectionUrl}
                    variant="primary"
                    size="lg"
                    active
                  >
                    {t("get_started")}
                  </Button>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className={"carouselItemImage"}>
                <Image
                  src="/assets/landing/Desktop/SVG/Group 1295.svg"
                  style={{ height: "45vh" }}
                  fluid
                />
              </div>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row style={{ alignItems: "center" }}>
            <Col md={2}></Col>
            <Col md={4}>
              <div className={"carouselItemText"}>
                <h1 className={"carouselTitle"}>{t("c3title")}</h1>
                <div className={"carouselText"}>
                  <p className={"carouselText"}>
                    <li>{t("c3li1")}</li>
                    <li>{t("c3li2")}</li>
                    <li>{t("c3li3")}</li>
                    <li>{t("c3li4")}</li>
                  </p>
                </div>
                <div className={"d-none d-sm-none d-md-block"}>
                  <Button
                    href={redirectionUrl}
                    variant="primary"
                    size="lg"
                    active
                  >
                    {t("get_started")}
                  </Button>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className={"carouselItemImage"}>
                <Image
                  src="/assets/landing/Desktop/SVG/Group 2437.svg"
                  style={{ height: "45vh" }}
                  fluid
                />
              </div>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Carousel.Item>
      </Carousel>
      <div className={"d-md-none d-lg-none p-3 text-center"}>
        <Button href={redirectionUrl} variant="primary" size="lg" active>
          {t("get_started")}
        </Button>
      </div>
    </section>
  );
};

Topcarousel.propTypes = {};

Topcarousel.defaultProps = {};

export default withNamespaces()(Topcarousel);
