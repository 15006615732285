const BACKEND_URL = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : "https://rt-backend.rightcomtech.com"

const apiUrl = {
    serverUrl: BACKEND_URL,
    alias: "righttime",
    decoder: '/api/decoder',
    mailing: "/public/request/demo",
    config: {
        headers: {
            alias: "righttime",
            "Content-Type": "application/json",
            "cache-control": "no-cache",
            "disable-validation": false
        }
    }
};
export default apiUrl;

