import React from "react";
import "./Featuretwoo.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { withNamespaces } from "react-i18next";

const styles = {
  featTitle: {
    fontSize: 24,
    color: "#373737",
    fontWeight: "bold",
    marginBottom: 20
  },
  featParag: {
    color: "#808080",
    fontSize: 14
  },
  feat: {
    marginBottom: 40
  }
};
const Featuretwoo = ({ t }) => (
  <div className={styles.Featuretwoo} data-testid="Featuretwoo">
    <section id={"feature2"} style={{ paddingTop: 150, paddingBottom: 90 }}>
      <Container>
        <div className={"f2Container"}>
          <Row>
            <Col>
              <div style={{ display: "flex", marginBottom: 56 }}>
                <Row>
                  <Col className={"d-none d-sm-block"}>
                    <Image
                      src="/assets/landing/General/SVG/Group 2429.svg"
                      style={{ width: 125, height: 125 }}
                    />
                  </Col>
                  <Col></Col>
                  <Col sm={7} md={8}>
                    <h3 style={styles.featTitle}>{t("f4_title")}</h3>
                    <p style={styles.featParag}>{t("f4_paragraph")}</p>
                  </Col>
                </Row>
              </div>
              <div style={{ display: "flex", marginBottom: 56 }}>
                <Row>
                  <Col className={"d-none d-sm-block"}>
                    <Image
                      src="/assets/landing/General/SVG/Group 2016.svg"
                      style={{ width: 125, height: 125 }}
                    />
                  </Col>
                  <Col></Col>
                  <Col sm={7} md={8}>
                    <h3 style={styles.featTitle}>{t("f5_title")}</h3>
                    <p style={styles.featParag}>{t("f5_paragraph")}</p>
                  </Col>
                </Row>
              </div>
              <div style={{ display: "flex", marginBottom: 56 }}>
                <Row>
                  <Col className={"d-none d-sm-block"}>
                    <Image
                      src="/assets/landing/General/SVG/Group 2401.svg"
                      style={{ width: 125, height: 125 }}
                    />
                  </Col>
                  <Col></Col>
                  <Col sm={7} md={8}>
                    <h3 style={styles.featTitle}>{t("f6_title")}</h3>
                    <div style={{}}>
                      <p style={styles.featParag}>{t("f5_paragraph")}</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  </div>
);

Featuretwoo.propTypes = {};

Featuretwoo.defaultProps = {};

export default withNamespaces()(Featuretwoo);
