import React, { useState } from "react";
import "./Ctatwoo.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { withNamespaces } from "react-i18next";

import RequestDemo from '../../feature/requestDemo/RequestDemo';


const styles = {
  ctaTitle: {
    fontSize: 14,
    color: "#0089E1",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: 0.14
  },
  cta: {
    textAlign: "center",
    backgroundColor: "#FAFAFA",
    paddingTop: 90,
    paddingBottom: 90
  },
  ctaText: {
    color: "#373737",
    fontSize: 30,
    marginTop: 16,
    marginBottom: 26,
    letterSpacing: 0
  }
};

function Ctatwoo({ t }) {
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  return (
    <section
      id={"ctawoo"}
      className={styles.Ctatwoo}
      data-testid="Ctatwoo"
      style={styles.cta}
    >
      <Container>
        <div className={"cta2container"}>
          <Row>
            <Col>
              <h3 style={styles.ctaTitle}>{t("cta2_text1")}</h3>
              <p style={styles.ctaText}>{t("cta2_text2")}</p>
              <Button variant="primary" size="lg" active className="" onClick={handleShow}>
                {t("reach_us")}
              </Button>
              <RequestDemo show={modalShow} onHide={handleClose} />
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  )
};

Ctatwoo.propTypes = {};

Ctatwoo.defaultProps = {};

export default withNamespaces()(Ctatwoo);
