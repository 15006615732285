import React from "react";
import styles from "./Videosection.module.css";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import { withNamespaces } from "react-i18next";

function Videosection({ t }) {
  const [videoShow, setVideoShow] = React.useState(true);
  return (
    <section
      id={"video"}
      className={styles.Videosection}
      data-testid="Videosection"
    >
      <div className={"videoBlue"}>
        <Container>
          <h2>{t("svideo_title")}</h2>
          <div className={"videoParagraph"}>
            <p>{t("svideo_paragraph")}</p>
          </div>
        </Container>
      </div>
      <div style={{ textAlign: "center" }}>
        <div className={"onTopVideo"}>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className={"embed-responsive-item"}
              title="vimeo-player"
              src="https://player.vimeo.com/video/181002384"
              width="100%"
              height="100%"
              frameBorder="0"
              allow="autoplay ; fullscreen"
              allowFullScreen
            ></iframe>
          </div>
          <div
            style={{
              position: "absolute",
              top: 0,
              display: videoShow ? "block" : "none",
              transition: "display 1s"
            }}
          >
            <Image src="assets/appointment-small.png" fluid />
            <div className={"centerplay"} onClick={() => setVideoShow(false)}>
              <Image
                src="assets/play.png"
                style={{ width: 52, height: 52 }}
                fluid
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: 1 }}>&nbsp;</div>
    </section>
  );
}

Videosection.propTypes = {};

Videosection.defaultProps = {};

export default withNamespaces()(Videosection);
