import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import "./App.css";
import {withNamespaces} from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import { NAVIGATION } from "./constants/constants";
import Landing from "./components/Landing/Landing";
import Feature from "./components/Feature/Feature";

const useStateWithLocalStorageOnb = localStorageKey => {
  const [onb, setOnb] = React.useState(
    localStorage.getItem(localStorageKey) || "false"
  );
  React.useEffect(() => {
    localStorage.setItem(localStorageKey, onb);
  }, [onb, localStorageKey]);
  return [onb, setOnb];
};

const useStateWithLocalStorageDash = localStorageKey => {
  const [dash, setDash] = React.useState(
    localStorage.getItem(localStorageKey) || "false"
  );
  React.useEffect(() => {
    localStorage.setItem(localStorageKey, dash);
  }, [dash, localStorageKey]);
  return [dash, setDash];
};

function App({ t }) {
  const [onb, setOnb] = useStateWithLocalStorageOnb("rightTime_onb");
  const [dash, setDash] = useStateWithLocalStorageDash("rightTime_dash");

  const mainRoutes = [
    { path: NAVIGATION.FEATURES, exact: false, component: Feature }
  ];

  return (
    <Router>
      <div className="App">
        <Switch>
          {mainRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          ))}
          <Route path="/">
            <Landing />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default withNamespaces()(App);
