import React, { useCallback, useEffect, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import styles from "./Landing.module.css";

import Footer from "../Footer/Footer";
import Topnavbar from "../Topnavbar/Topnavbar";
import Topcarousel from "../landing/Topcarousel/Topcarousel";
import Blacknav from "../Blacknav/Blacknav";
import Videosection from "../landing/Videosection/Videosection";
import Featuretwoo from "../landing/Featuretwoo/Featuretwoo";
import Featureone from "../landing/Featureone/Featureone";
import Ctatwoo from "../landing/Ctatwoo/Ctatwoo";
import Ctaone from "../landing/Ctaone/Ctaone";
import useHttpService from "../../services/HttpService";
import { useDispatch } from "redux-react-hook";

function Landing(props) {
  const hasMount = useRef(false);
  const unmounted = useRef(false);
  const [stateCheckSession, setStateCheckSession] = useState("loading");
  const {
    send: sendRequestCheckSession,
    data: sessionResponse,
    loading: onLoadingSession,
    error: onErrorSession
  } = useHttpService("CHECK_SESSION");
  const dispatch = useDispatch();
  const updateUserInfo = useCallback(
    user => dispatch({ type: "UPDATE_USER_INFO", value: user }),
    [dispatch]
  );
  const updateUserIsConnected = useCallback(
    status => dispatch({ type: "UPDATE_USER_IS_CONNECTED", value: status }),
    [dispatch]
  );
  const updateUserFullName = useCallback(
    fullName => dispatch({ type: "UPDATE_USER_FULLNAME", value: fullName }),
    [dispatch]
  );

  useEffect(() => {
    if (!hasMount.current) {
      // componentDidMount
      checkSession();
      hasMount.current = true;
    } else {
      // componentDidUpdate
    }
    return () => {
      unmounted.current = true;
      // componentWillUnmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // CheckSession submit result hook
  useEffect(() => {
    if (!unmounted.current) {
      if (!onLoadingSession && onErrorSession) {
        setStateCheckSession("error");
      }
      if (!onLoadingSession && sessionResponse) {
        if (sessionResponse.status === 200) {
          // user session is ok
          // set user session
          updateUserInfo(sessionResponse.user);
          updateUserFullName(
            sessionResponse.user.firstname + " " + sessionResponse.user.lastname
          );
          updateUserIsConnected(true);
          setStateCheckSession("success");
          //props.onLoaded(true);
        } else if (sessionResponse.status === 201) {
          // user session is ok
          // set user session
          updateUserInfo(sessionResponse.user);
          updateUserFullName(
            sessionResponse.user.firstname + " " + sessionResponse.user.lastname
          );
          updateUserIsConnected(true);
          setStateCheckSession("success");
          //props.onLoaded(true);
        } else {
          setStateCheckSession("error");
        }
      }
    }
  }, [
    onLoadingSession,
    onErrorSession,
    sessionResponse,
    props,
    updateUserInfo,
    updateUserFullName,
    updateUserIsConnected
  ]);

  function checkSession() {
    sendRequestCheckSession();
  }
  //if(updateUserIsConnected){window.location="/dashboard";}
  return (
    <div className={styles.Landing} data-testid="Landing">
      <Topnavbar />
      <Topcarousel />
      <Blacknav />
      <Videosection />
      <Featureone />
      <Ctaone />
      <Featuretwoo />
      <Ctatwoo />
      <Footer />
    </div>
  );
}

Landing.propTypes = {};

Landing.defaultProps = {};

export default withNamespaces()(Landing);
