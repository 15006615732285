export default function reducer(state, action) {
  if (!state) {
    return null;
  }

  switch (action.type) {
    case "UPDATE_USER_IS_CONNECTED": {
      /** use 4 */
      //console.log("** UPDATE_USER_IS_CONNECTED");
      //console.log("state : ", { ...state });
      //console.log("userIsConnected : ", { userIsConnected: action.value });

      return { ...state, userIsConnected: action.value };
    }
    case "UPDATE_USER_INFO": {
      /** use 2 */
      //console.log("** UPDATE_USER_INFO");
      //console.log("state : ", { ...state });
      //console.log("userInfo : ", { userInfo: action.value });

      return { ...state, userInfo: action.value };
    }
    case "UPDATE_SOCKET_INSTANCE": {
      /** use 1 */
      //console.log("** UPDATE_SOCKET_INSTANCE");
      //console.log("state : ", { ...state });
      //console.log("socketIoInstance : ", { socketIoInstance: action.value });

      return { ...state, socketIoInstance: action.value };
    }
    case "UPDATE_USER_FULLNAME": {
      /** use 3 */
      //console.log("** UPDATE_USER_FULLNAME");
      //console.log("state : ", { ...state });
      //console.log("userFullName : ", { userFullName: action.value });

      return { ...state, userFullName: action.value };
    }
    case "UPDATE_VIEW_ONBOARDING": {
      //console.log("** UPDATE_VIEW_ONBOARDING");
      //console.log("state : ", { ...state });
      //console.log("viewOnbording : ", { viewOnbording: action.value });

      return {
        ...state,
        viewOnbording: action.value, //*
        viewApp: false,
        viewDashboard: false
      };
    }
    case "UPDATE_VIEW_APP": {
      //console.log("** UPDATE_VIEW_APP");
      //console.log("state : ", { ...state });
      //console.log("viewApp : ", { viewApp: action.value });

      return {
        ...state,
        viewOnbording: false,
        viewApp: action.value, //*
        viewDashboard: false
      };
    }
    case "UPDATE_VIEW_DASHBOARD": {
      //console.log("** UPDATE_VIEW_DASHBOARD");
      //console.log("state : ", { ...state });
      //console.log("viewApp : ", { viewApp: action.value });

      return {
        ...state,
        viewOnbording: false,
        viewApp: false,
        viewDashboard: action.value //*
      };
    }
    case "USE_NOTIFICATION": {
      //console.log("** USE_NOTIFICATION");
      //console.log("state : ", { ...state });
      /*console.log(
          "useNotification : ",
          action.active,
          action.typeNotif,
          action.textNotif
      );*/

      return {
        ...state,
        useNotification: {
          active: action.active,
          type: action.typeNotif,
          text: action.textNotif
        }
      };
    }
    case "SCHEDULE_URL": {
      //console.log("state : ", { ...state });

      return { ...state, schedulingUrl: action.value };
    }
    case "SCHEDULE_NAME": {
      //console.log("state : ", { ...state });

      return { ...state, schedulingNane: action.value };
    }
    case "SCHEDULE_TIMEZONE": {
      //console.log("state : ", { ...state });

      return { ...state, schedulingTimeZone: action.value };
    }
    case "SCHEDULE_DAYS": {
      //console.log("state : ", { ...state });

      return { ...state, schedulingDays: action.value };
    }
    default:
      return state;
  }
}
