import React from "react";
import "./Featureone.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { withNamespaces } from "react-i18next";

function Featureone({ t }) {
  const styles = {
    featTitle: {
      fontSize: 24,
      color: "#373737",
      fontWeight: "bold",
      marginBottom: 20
    },
    featParag: {
      color: "#808080",
      fontSize: 14
    },
    feat: {
      marginBottom: 40
    }
  };
  return (
    <section
      id={"feature1"}
      style={{ position: "relative", marginBottom: 96 }}
      className={styles.Featureone}
      data-testid="Featureone"
    >
      <Container>
        <div className={"f1Container"}>
          <Row>
            <Col sm={7}>
              <div></div>
              <div style={{ maxWidth: 320, margin: "auto" }}>
                <div style={styles.feat}>
                  <h3 style={styles.featTitle}>{t("f1_title")}</h3>
                  <p style={styles.featParag}>{t("f1_paragraph")}</p>
                </div>
                <div style={styles.feat}>
                  <h3 style={styles.featTitle}>{t("f2_title")}</h3>
                  <p style={styles.featParag}>{t("f2_paragraph")}</p>
                </div>
                <div style={styles.feat}>
                  <h3 style={styles.featTitle}>{t("f3_title")}</h3>
                  <p style={styles.featParag}>{t("f3_paragraph")}</p>
                </div>
              </div>
            </Col>
            <Col sm={5} style={{ textAlign: "center" }}>
              <Image src="/assets/landing/Desktop/SVG/desktop.svg" fluid />
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
}

Featureone.propTypes = {};

Featureone.defaultProps = {};

export default withNamespaces()(Featureone);
