import React, { useState } from "react";
import 'font-awesome/css/font-awesome.min.css';
import "./Feature.css";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import Cookies from "universal-cookie";

import Footer from "../Footer/Footer";
import Topnavbar from "../Topnavbar/Topnavbar";
import RequestDemo from "../feature/requestDemo/RequestDemo";


function Feature({ t }) {
  const cookies = new Cookies();
  const redirectionUrl = cookies.get("rightTimeAlias")
    ? "https://" + cookies.get("rightTimeAlias") + ".righttime.rightcomtech.com"
    : "https://account.rightcomtech.com"; 

  const go = () => (window.location = redirectionUrl);

  const [modalShow, setModalShow] = useState(false);

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  return (
    <>
      <Topnavbar />
      <section id={"feature"} data-testid="Feature">
        <Container fluid className="zone">
          <Row>
            <Col md={{ span: 5 }}>
              <div className="">
                <h3 className="header-title">
                  {t("cfeat0_header")}
                </h3>
                <Button variant="primary" size="lg" className="get-started" onClick={go} href={redirectionUrl}>
                  {t("get_started")}
                </Button>
              </div>
              <div className="d-md-none big-logo-xs">
                <Image src="/assets/feature/big-logo-png.png" fluid />
              </div>
              <div className="d-inline-flex">
                <span className="align-self-start image-left">
                  <Image src={"/assets/feature/" + t("local_lang") +"/Group 10057.png"} fluid />
                </span>
                <span className="align-self-end image-right">
                  <Image src="/assets/feature/Mask Group 27.png" fluid />
                </span>
              </div>
            </Col>
            <Col md={{ span: 7, order: 'first' }}>
              <div className="big-logo-md"></div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col sm={{ span: 3, offset: 2 }}>
              <div className="section-info">
                <h3 className="title">{t("cfeat1_title")}</h3>
                <p className="description">{t("cfeat1_desc")}</p>
              </div>
            </Col>
            <Col sm={5}>
              <Image src={"/assets/feature/" + t("local_lang") + "/Group 10053.png"} fluid />
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col sm={{ span: 4, offset: 1 }}>
              <div className="section-info">
                <div className="email-logo">
                  <Image src="/assets/feature/Group 10027.png" />
                </div>
                <h3 className="title">{t("cfeat2_title")}</h3>
                <p className="description">{t("cfeat2_desc")}</p>
              </div>
            </Col>
            <Col sm={{ span: 6, order: 'first' }}>
              <Image src={"/assets/feature/" + t("local_lang") + "/Group 10086.png"} fluid />
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 4, offset: 2 }}>
              <div className="section-info">
                <h3 className="title">{t("cfeat3_title")}</h3>
                <p className="description">{t("cfeat3_desc")}</p>
              </div>
            </Col>
            <Col sm={6}>
              <Image src={"/assets/feature/" + t("local_lang") + "/Mask Group 32.png"} fluid />
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 4, offset: 1 }}>
              <div className="section-info">
                <h3 className="title">{t("cfeat4_title")}</h3>
                <p className="description">{t("cfeat4_desc")}</p>
              </div>
            </Col>
            <Col sm={{ span: 6, order: 'first' }}>
              <Image src={"/assets/feature/" + t("local_lang") + "/Mask Group 33.png"} fluid />
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 4, offset: 2 }}>
              <div className="section-info">
                <h3 className="title">{t("cfeat5_title")}</h3>
                <p className="description">{t("cfeat5_desc")}</p>
              </div>
            </Col>
            <Col sm={6}>
              <Image src={"/assets/feature/" + t("local_lang") + "/Mask Group 34.png"} fluid />
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 4, offset: 1 }}>
              <div className="section-info">
                <h3 className="title">{t("cfeat6_title")}</h3>
                <p className="description">{t("cfeat6_desc")}</p>
              </div>
            </Col>
            <Col sm={{ span: 6, order: 'first' }}>
              <Image src={"/assets/feature/" + t("local_lang") + "/Mask Group 35.png"} fluid />
            </Col>
          </Row>
          <Row className="request-demo">
            <Col sm={4} className="position-xs">
              <Image src={"/assets/feature/Group 10582.png"} fluid className="request-image"/>
            </Col>
            <Col sm={{ span: 5, offset: 1, order: 'first' }} className="position-xs">
              <div className="request-body position-xs">
                <h5 className="request-title">{t("cfeat_request_demo")}</h5>
                <Button variant="primary" size="sm" className="" onClick={handleShow}>
                  {t("cfeat_request_demo_btn")}
                </Button>
                <RequestDemo show={modalShow} onHide={handleClose} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}

Feature.propTypes = {};

Feature.defaultProps = {};

export default withNamespaces()(Feature);
