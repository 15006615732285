import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  }
};

if (!localStorage.getItem("rightTime_lang")) {
  let l = navigator.language;
  if (l.includes("en")) {
    localStorage.setItem("rightTime_lang", "en");
  } else if (l.includes("fr")) {
    localStorage.setItem("rightTime_lang", "fr");
  }
}
const definedLan = localStorage.getItem("rightTime_lang");

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: definedLan,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      wait: true
    },
    fallbackLng: "en"
  });

export default i18n;
