import { useCallback } from "react";
import { useMappedState } from "redux-react-hook";

function GetStore() {
  const mapState = useCallback(state => state, []);
  const data = useMappedState(mapState);
  return data;
}

export default GetStore;
