import React from "react";
import "./Ctaone.module.css";
import Container from "react-bootstrap/Container";
import { withNamespaces } from "react-i18next";
const styles = {
  ctaTitle: {
    fontSize: 14,
    color: "#0089E1",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: 0.14
  },
  cta: {
    textAlign: "center",
    backgroundColor: "#FAFAFA",
    paddingTop: 97,
    paddingBottom: 89
  },
  ctaText: {
    color: "#373737",
    fontSize: 30,
    marginTop: 16,
    marginBottom: 26,
    letterSpacing: 0
  }
};
const Ctaone = ({ t }) => (
  <div className={styles.Ctaone} data-testid="Ctaone">
    <section style={styles.cta} className={"Ctaone"}>
      <Container>
        <div>
          <h3 style={styles.ctaTitle}>{t("cta1_text1")}</h3>
          <p style={styles.ctaText}>{t("cta1_text2")}</p>
        </div>
      </Container>
    </section>
  </div>
);

Ctaone.propTypes = {};

Ctaone.defaultProps = {};

export default withNamespaces()(Ctaone);
