import React from "react";
import "./RequestDemo.css";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import 'font-awesome/css/font-awesome.min.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import axios from "axios";
import apiUrl from "../../../datas/apiUrl";
import i18n from "../../../i18n"
class RequestDemo extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            fullname: { value: "", error: "hide" },
            email: { value: "", error: "hide" },
            number: { value: "", error: "hide" },
            comment: { value: "" },
            submitted: false,
            message: ""
        }
        this.handleFullnameChange = this.handleFullnameChange.bind(this)
        this.handleEmailChange = this.handleEmailChange.bind(this)
        this.handleNumberChange = this.handleNumberChange.bind(this)
        this.handleCommentChange = this.handleCommentChange.bind(this)
        this.handleSubmitForm = this.handleSubmitForm.bind(this)
    }

    handleFullnameChange(e) {
        this.setState({ 
            fullname: {
                value: e.target.value,
                error: (e.target.value !== "" && e.target.value.length < 3) ? "display" : "hide"
            }
        })
    }

    handleEmailChange(e) {
        this.setState({
            email: {
                value: e.target.value.toLowerCase(),
                error: (e.target.value !== "" && !/^[a-z0-9\.]+@[a-z0-9-]+\.[a-z][a-z]+$/.test(e.target.value.toLowerCase())) ? "display" : "hide"
            }
        })
    }

    handleNumberChange(val) {
        val = val ? val.trim() : ''
        console.log(val)
        this.setState({
            number: {
                value: val,
                error: (val !== "" && (!/^\+?[0-9]+$/.test(val) || !isValidPhoneNumber(val))) ? "display" : "hide"
            }
        })
    }

    handleCommentChange(e) {
        this.setState({
            comment: { value: e.target.value }
        })
    }

    validateForm() {
        const {email} = this.state
        if (email.value == "" || email.error == "display") {
            this.setState({ 
                email: { value: email.value, error: "display" },
                submitted: false, 
                message: this.props.t("rdemo_email_required")
            })
            return false
        }
        else {
            this.setState({
                submitted: true,
                message: this.props.t("rdemo_send_request")
            })
            return true
        }
    }

    handleSubmitForm() {
        const isValidForm = this.validateForm()
        
        if (isValidForm) {
            const postData = {
                fullname: this.state.fullname.value,
                email: this.state.email.value,
                phoneNumber: this.state.number.value,
                comment: this.state.comment.value,
                locale: i18n.language
            };

            axios.post(apiUrl.serverUrl + apiUrl.mailing, postData, apiUrl.config)
                .then((res) => {
                    console.log("RESPONSE RECEIVED: ", res)
                    if (res.data) {
                        this.setState({
                            fullname: { value: "", error: "hide" },
                            email: { value: "", error: "hide" },
                            number: { value: "", error: "hide" },
                            comment: { value: "" },
                            submitted: false,
                            message: this.props.t("rdemo_send_success")
                        })
                    }
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err)
                    this.setState({ submitted: false, message: this.props.t("rdemo_send_error") })
                })
        }
    }

    render () {
        const { t, show, onHide } = this.props
        return <Modal show={show} onHide={onHide} size="xl" aria-labelledby="example-custom-modal-styling-title-xl">
            <Modal.Header closeButton className="rdemo-header">
                <Modal.Title id="example-custom-modal-styling-title" className="rdemo-header-title">
                    {t("cfeat_request_demo_btn")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <Container>
                    <Row>
                        <Col xs={12} md={6} className="rdemo-body-move">
                            <Form>
                                <Form.Group controlId="formBasicFullname">
                                    <Form.Label className="font-weight-bold">{t("rdemo_fullname_title")}</Form.Label>
                                    <Form.Control type="text" placeholder={t("rdemo_fullname_text")} value={this.state.fullname.value} onChange={this.handleFullnameChange} />
                                    <Form.Text className={"text-danger " + this.state.fullname.error}>
                                        {t("rdemo_fullname_error")}
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label className="font-weight-bold">{t("rdemo_email_title")}</Form.Label>
                                    <Form.Control type="email" placeholder={t("rdemo_email_text")} value={this.state.email.value} onChange={this.handleEmailChange} />
                                    <Form.Text className={"text-danger " + this.state.email.error}>
                                        {t("rdemo_email_error")}
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formBasicNumber">
                                    <Form.Label className="font-weight-bold">{t("rdemo_phonenumber_title")}</Form.Label>
                                    <PhoneInput className="form-control phone-style"
                                        value={this.state.number.value}
                                        onChange={this.handleNumberChange}
                                        placeholder={t("rdemo_phonenumber_text")}
                                    />
                                    <Form.Text className={"text-danger " + this.state.number.error}>
                                        {t("rdemo_phonenumber_error")}
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label className="font-weight-bold">{t("rdemo_comment_title")}</Form.Label>
                                    <Form.Control as="textarea" rows="3" value={this.state.comment.value} onChange={this.handleCommentChange} />
                                </Form.Group>

                                <Button variant="primary" type="button" size={'lg'} disabled={this.state.submitted} className="text-uppercase p-2" onClick={this.handleSubmitForm}>
                                    {t("rdemo_btn")}
                                    <span className={this.state.submitted ? "display" : "hide"}>
                                        <div className="spinner-grow spinner-border-sm ml-1 text-danger" role="status" style={{ width: "15px", height: "15px" }}>
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <div className="spinner-grow spinner-border-sm ml-1 text-danger" role="status" style={{ width: "15px", height: "15px" }}>
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <div className="spinner-grow spinner-border-sm ml-1 text-danger" role="status" style={{ width: "15px", height: "15px" }}>
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </span>
                                </Button>
                                <span className="ml-3 text-info">{this.state.message}</span>
                            </Form>
                        </Col>
                        <Col xs={12} md={6} className="my-md-auto rdemo-body-details">
                            <h4 className="rdemo-body-title">{t("c2title")}</h4>
                            <div className="rdemo-body-list">
                                <div><i className="fa fa-sliders fa-color"></i> {t("cfeat1_title")}</div>
                                <div><i className="fa fa-calendar-check-o fa-color"></i> {t("cfeat2_title")}</div>
                                <div><i className="fa fa-qrcode fa-color"></i> {t("cfeat3_title")}</div>
                                <div><i className="fa fa-calendar-check-o fa-color"></i> {t("cfeat4_title")}</div>
                                <div><i className="fa fa-users fa-color"></i> {t("cfeat5_title")}</div>
                                <div><i className="fa fa-pie-chart fa-color"></i> {t("cfeat6_title")}</div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    }
}

RequestDemo.propTypes = {};

RequestDemo.defaultProps = {};

export default withNamespaces()(RequestDemo);
